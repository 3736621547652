import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d528920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "lastMsg"
}
const _hoisted_3 = {
  key: 1,
  class: "lastMsg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: "userWrap",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.user.toContact(_ctx.name, _ctx.chatType)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(
        _ctx.chatType !== _ctx.user.CHAT_TYPE.singleChat
          ? 'avatar defaultGroupAvatar'
          : 'avatar defaultAvatar'
      ),
      style: _normalizeStyle(
        _ctx.user.info?.avatarurl
          ? `background: url(${_ctx.user.info.avatarurl});background-size: 100%`
          : ''
      )
    }, [
      (_ctx.unReadCount)
        ? (_openBlock(), _createBlock(_component_Badge, {
            key: 0,
            content: _ctx.unReadCount
          }, null, 8, ["content"]))
        : _createCommentVNode("", true)
    ], 6),
    _createElementVNode("div", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.chatType === _ctx.user.CHAT_TYPE.groupChat
          ? `${_ctx.user.info?.name || _ctx.name}`
          : _ctx.user.info.nickname || _ctx.name), 1),
      (_ctx.lastMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.lastMessage.type === 'txt')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.lastMessage.msg), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(`[${_ctx.lastMessage.type}]`), 1))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}