import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c2a6be8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_User = _resolveComponent("User")!

  return (_openBlock(), _createElementBlock("div", {
    class: "itemWrap",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.user.toContact(_ctx.name, _ctx.chatType)))
  }, [
    _createVNode(_component_User, {
      chatType: _ctx.chatType,
      name: _ctx.name
    }, null, 8, ["chatType", "name"]),
    (!_ctx.isEnd)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ]))
}