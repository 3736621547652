
import { Options, Vue, setup } from "vue-class-component";
import { NavBar, Button, Field } from "vant";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import ContactItem from "./contactItem.vue";
import { AllState } from "@/store";
import { EasemobChat } from "easemob-websdk/Easemob-chat";

@Options({
  components: {
    NavBar,
    Button,
    Field,
    ContactItem
  }
})
export default class Contact extends Vue {
  contact = setup(() => {
    const store = useStore<AllState>();
    let deviceList = ref<string[]>([]);
    let userList = ref<string[]>([]);
    let groupList = ref<EasemobChat.BaseGroupInfo[]>([]);
    let roomList = ref<EasemobChat.BaseGroupInfo[]>([]);

    const getSelfDeviceList = () => {
      //@ts-ignore
      store.state.IM.connect?.getSelfIdsOnOtherPlatform?.()?.then((res) => {
        deviceList.value = res?.data || [];
        console.log(res, "getSelfIdsOnOtherPlatform Res");
      });
    };

    const getFriendList = () => {
      store.state.IM.connect.getContacts().then((res) => {
        userList.value = res?.data || [];
      });
    };

    const getJoinedGroupList = () => {
      store.state.IM.connect
        .getJoinedGroups({
          pageNum: 1,
          pageSize: 500
        })
        .then((res) => {
          let dt = (res.data || []) as EasemobChat.BaseGroupInfo[];
          groupList.value = dt;
        });
    };

    const getRooms = () => {
      store.state.IM.connect
        .getChatRooms({ pagenum: 1, pagesize: 500 })
        .then((res) => {
          let dt = res.data || ([] as any);
          roomList.value = dt;
        });
    };

    onMounted(() => {
      getFriendList();
      getJoinedGroupList();
      getRooms();
      getSelfDeviceList();
    });
    return {
      userList,
      groupList,
      roomList,
      deviceList,
      getFriendList,
      getSelfDeviceList
    };
  });
}
