
import { EasemobChat } from "easemob-websdk/Easemob-chat";
import { Options, Vue, setup } from "vue-class-component";
import { useRouter, Router } from "vue-router";
import { CHAT_TYPE } from "@/const";
import { onMounted, getCurrentInstance, reactive } from "vue";
import { AllState } from "../store";
import { useStore } from "vuex";
import { MessageBody } from "easemob-websdk/src/types/message";
import { Badge } from "vant";

class Props {
  name!: string;
  chatType!: CHAT_TYPE;
  lastMessage?: MessageBody;
  unReadCount?: number;
}

interface ConversationInfo extends EasemobChat.UpdateOwnUserInfoParams {
  name?: string;
}

@Options({
  props: {
    name: "", // 默认名称uid
    chatType: CHAT_TYPE,
    lastMessage: null,
    unReadCount: 0
  },
  components: {
    Badge
  }
})
export default class Home extends Vue.with(Props) {
  user = setup(() => {
    const store = useStore<AllState>();
    const router: Router = useRouter();
    const context = getCurrentInstance();
    const info = reactive<ConversationInfo>({});
    const conn = store.state.IM.connect;
    const toContact = (id: string, type: CHAT_TYPE) => {
      router.push(`/chat/to/${type}/${encodeURIComponent(id)}`);
    };

    const getUserInfo = () => {
      if (context?.props.chatType === CHAT_TYPE.singleChat) {
        conn.fetchUserInfoById(context?.props.name as string).then((res) => {
          Object.assign(info, res.data?.[context?.props.name as string], {
            success: true
          });
        });
      } else if (context?.props.chatType === CHAT_TYPE.groupChat) {
        conn
          .getGroupInfo({ groupId: context?.props.name as string })
          .then((res) => {
            Object.assign(info, res.data?.[0], {
              success: true
            });
          });
        Object.assign(info, {
          success: true
        });
      } else {
        Object.assign(info, {
          success: true
        });
      }
    };

    onMounted(() => {
      getUserInfo();
    });

    return {
      toContact,
      CHAT_TYPE,
      info
    };
  });
}
