
import { Options, Vue, setup } from "vue-class-component";
import { useRouter, Router } from "vue-router";
import { CHAT_TYPE } from "@/const";
import User from "@/components/user.vue";

class props {
  name!: string;
  chatType!: CHAT_TYPE;
  isEnd!: boolean;
}

@Options({
  props: {
    name: "",
    chatType: CHAT_TYPE,
    isEnd: false
  },
  components: {
    User
  }
})
export default class Home extends Vue.with(props) {
  user = setup(() => {
    const router: Router = useRouter();
    const toContact = (id: string, type: CHAT_TYPE) => {
      router.push(`/chat/to/${type}/${encodeURIComponent(id)}`);
    };
    return {
      toContact,
      CHAT_TYPE
    };
  });
}
